import React from "react"
import { Link as A } from "gatsby"
import { CgArrowRight } from "react-icons/cg"

import { arrowLink } from "./link.module.css"

type Props = {
  variant?: "default" | "arrow"
  url: {
    type: "internal" | "external"
    href: string
  }
  classOverrides?: string
}

const StyledLink: React.FC<Props> = ({
  variant = "default",
  url,
  children,
  classOverrides,
}) => {
  if (url.type === "internal") {
    return (
      <A
        className={`inline-flex items-center text-base font-medium transition-all ${
          variant === "arrow" ? arrowLink : ""
        } ${classOverrides}`}
        to={url.href}
      >
        <span>{children}</span>
        {variant === "arrow" && (
          <span className="text-xl ml-1 transition-all">
            <CgArrowRight />
          </span>
        )}
      </A>
    )
  } else {
    return (
      <a
        className={`inline-flex items-center text-base font-medium transition-all ${
          variant === "arrow" ? arrowLink : ""
        } ${classOverrides}`}
        href={url.href}
      >
        <span>{children}</span>
        {variant === "arrow" && (
          <span className="text-xl ml-1 transition-all">
            <CgArrowRight />
          </span>
        )}
      </a>
    )
  }
}

export default StyledLink
