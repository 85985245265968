import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "@components/Layout"
import PostCard from "@components/PostCard"
import Seo from "@components/Seo"
import Pagination from "@components/Pagination"
import * as Breadcrumb from "@components/Breadcrumb"

const PublicationsArchive = ({
  data,
  pageContext: { nextPagePath, previousPagePath, currentPage, numPages },
}) => {
  const { publications, pageMeta } = data

  return (
    <Layout>
      <Seo yoastSeo={pageMeta?.seo} />

      {currentPage === 1 ? (
        <section className="bg-slate-100 dark:bg-slate-800 py-20">
          <div className="container--boxed">
            <h1 className="h1 text-center">Publications</h1>
          </div>
        </section>
      ) : (
        <div className="bg-slate-100 dark:bg-slate-800 py-10">
          <div className="container--boxed">
            <Breadcrumb.Main>
              <Breadcrumb.Item href="/publications/">
                Publications
              </Breadcrumb.Item>
              <Breadcrumb.Separator />
              <Breadcrumb.Item href={`/publications/${currentPage}/`} active>
                Page {currentPage} of {numPages}
              </Breadcrumb.Item>
            </Breadcrumb.Main>
          </div>
        </div>
      )}

      <section className="mt-12">
        <div className="container--boxed">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-9 sm:gap-x-6 sm:gap-y-10  lg:gap-x-10 lg:gap-y-14">
            {publications.edges.map(({ node }, index) => {
              return (
                <PostCard
                  key={node.id}
                  title={node.title}
                  excerpt={node.excerpt.replace(/<[^>]+>/g, "")}
                  image={{
                    gatsbyImage:
                      node.featuredImage?.node?.localFile?.childImageSharp
                        ?.gatsbyImageData,
                    alt: node.featuredImage?.node?.altText || node.title,
                  }}
                  link={{
                    type: "internal",
                    url: `/publications/${node.slug}/`,
                  }}
                  date={{
                    unFormatted: node.dateMachineReadable,
                    formatted: node.date,
                  }}
                />
              )
            })}
          </div>

          <Pagination
            {...{ previousPagePath, nextPagePath, currentPage, numPages }}
            classOverrides="mt-20"
          />
        </div>
      </section>
    </Layout>
  )
}

export default PublicationsArchive

export const pageQuery = graphql`
  query WordPressPublicationsArchive($offset: Int!, $postsPerPage: Int!) {
    publications: allWpPublication(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      edges {
        node {
          ...WpPublicationCardFields
        }
      }
    }

    pageMeta: wpPage(slug: { eq: "publications" }) {
      ...WpPageFields
    }
  }
`
