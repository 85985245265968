import React from "react"
import { Link } from "gatsby"
import { FiChevronRight } from "react-icons/fi"

const Main = ({ children }) => {
  return (
    <nav aria-label="Breadcrumb" className="flex items-center">
      <ul className="list-none flex items-center flex-wrap">
        <Item href="/">Home</Item>
        <Separator />
        {children}
      </ul>
    </nav>
  )
}

const Item = ({ href, children, active }) => (
  <li>
    <Link to={href}>
      <a
        className={`text-sm md:text-sm ${
          active
            ? "text-slate-400 dark:text-slate-400 font-normal "
            : "text-neutral-900 dark:text-slate-200 font-[450] hover:underline transition "
        }`}
      >
        {children}
      </a>
    </Link>
  </li>
)

const Separator = () => (
  <span className="mx-1 md:mx-2 text-base text-slate-400 dark:text-slate-500">
    <FiChevronRight />
  </span>
)

export { Main, Item, Separator }
