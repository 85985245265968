import React from "react"
import { Link } from "gatsby"
import { BsArrowRightCircle, BsArrowLeftCircle } from "react-icons/bs"

const Pagination = ({
  previousPagePath,
  nextPagePath,
  currentPage,
  numPages,
  classOverrides,
}) => (
  <nav
    className={`flex items-center justify-between relative ${classOverrides}`}
  >
    <div className="text-base text-slate-400 absolute w-full flex items-center justify-center h-full">
      Page {currentPage} / {numPages}
    </div>
    <div className="relative z-0">
      {previousPagePath && (
        <Link
          to={previousPagePath}
          rel="prev"
          className="flex items-center text-slate-400 hover:text-slate-700 dark:hover:text-slate-300 transition-colors text-base font-medium"
        >
          <span className="mr-3 text-3xl text-slate-700 dark:text-slate-300">
            <BsArrowLeftCircle />
          </span>
          Newer
        </Link>
      )}
    </div>
    <div className="relative z-0">
      {nextPagePath && (
        <Link
          className="flex items-center text-slate-400 hover:text-slate-700 dark:hover:text-slate-300 transition-colors text-base font-medium"
          to={nextPagePath}
          rel="next"
        >
          Older{" "}
          <span className="ml-3 text-3xl text-slate-700 dark:text-slate-300">
            <BsArrowRightCircle />
          </span>
        </Link>
      )}
    </div>
  </nav>
)

export default Pagination
