import React from "react"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Link } from "gatsby"
import StyledLink from "@components/Link"

type Props = {
  title: string
  image?: {
    gatsbyImage?: IGatsbyImageData
    alt?: string
  }
  excerpt?: string
  link: {
    type: "internal" | "external"
    url: string
  }
  date?: {
    formatted: string
    unFormatted: string
  }
}

const PostCard: React.FC<Props> = ({ title, image, excerpt, date, link }) => {
  return (
    <article className="relative">
      <div>
        {image?.gatsbyImage && (
          <GatsbyImage
            image={getImage(image.gatsbyImage)}
            alt={image?.alt || title}
            className=" shadow-sm rounded-sm"
            imgClassName="rounded-sm"
          />
        )}
      </div>
      <div className="flex flex-col mt-4">
        <h2 className="text-lg sm:text-xl font-semibold dark:text-slate-200">
          {title}
        </h2>
        {excerpt && (
          <p
            className="mt-3 text-[15px] text-slate-600 dark:text-slate-400 line-clamp-3"
            dangerouslySetInnerHTML={{ __html: excerpt }}
          ></p>
        )}
        {link.type === "internal" ? (
          <Link to={link.url} className="absolute top-0 left-0 w-full h-full" />
        ) : (
          <a href={link.url} className="absolute top-0 left-0 w-full h-full" />
        )}
        <div className="grow"></div>
        <aside className="mt-4">
          <StyledLink
            variant="arrow"
            url={{ type: link?.type, href: link?.url }}
          >
            Read more
          </StyledLink>
          {/* {date?.formatted && date?.unFormatted && (
            <div className="flex flex-col">
              <small className="text-sm text-slate-400">Published on</small>
              <time
                className="text-sm font-medium text-slate-600 dark:text-slate-400 dark:font-normal mt-1"
                dateTime={date.unFormatted}
              >
                {date.formatted}
              </time>
            </div>
          )} */}
        </aside>
      </div>
    </article>
  )
}

export default PostCard
